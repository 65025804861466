<template>
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <!--搜索条件-->
    <el-card shadow="never" style="margin-top: 20px;">
      <div class="card">
        <div class="card_t">
          <div class="card_t_icon"></div>
          <div class="card_t_text">
            筛选条件
          </div>
        </div>
        <div class="card_b">
          <!-- 搜索表单 -->
          <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
            <el-row :gutter="15">
              <el-col :lg="6" :md="12">
                <el-form-item label="关键字:">
                  <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="手机号/微信昵称"/>
                </el-form-item>
              </el-col>
              <el-col :lg="6" :md="12">
                <el-form-item label="状态:">
                  <el-select
                    clearable
                    v-model="where.status"
                    placeholder="请选择状态"
                    class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.common_status"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="注册时间:">
                  <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.time"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                  <!--<DatePicker v-model="where.time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :lg="4" :md="12">
                <div class="ele-form-actions" style="text-align: center;">
                  <el-button
                    type="primary"
                    class="ele-btn-icon"
                    @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>

    <!--数据表格-->
    <el-card shadow="never">
      <div class="card">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              共计: {{total}} 个用户
            </div>
          </div>
        </div>

        <div class="card_b">
          <!-- 数据表格 -->
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
            <!--自定义列显示 示例-->
            <template v-slot:status= "{ scope }">
              <div v-for="(item,index) in dict.type.common_status" :key="index">
                <span v-if="parseInt(item.value) == scope.row.status">{{item.label}}</span>
              </div>
            </template>

            <!--自定义列显示 示例-->
            <template v-slot:avatar= "{ scope }">
              <div>
                <img  style="cursor: pointer" :src="scope.row.avatar" alt="">
              </div>
            </template>

            <template v-slot:authentication_status= "{ scope }">
              <div v-if="scope.row.authentication_status == 0">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #ff9e1c;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                <span>停用</span>
              </div>
              <div v-if="scope.row.authentication_status == 2">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: red;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                <span>禁用</span>
              </div>
              <div v-if="scope.row.authentication_status == 1">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #25eb61;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                <span>启用</span>
              </div>
            </template>

            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}ordinary_custom:list:info`]">详情</el-link>
              <el-link :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}ordinary_custom:list:status`]" v-if="scope.row.status === 0" @click="Enable(scope.row)">停用</el-link>
              <el-link :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}ordinary_custom:list:status`]" v-if="scope.row.status === 1" style="margin-right: 10px;" @click="Disable(scope.row)">启用</el-link>
              <!--<el-link :underline="false" @click="del(scope.row)">删除</el-link>-->
            </template>
          </my-table>

        </div>
      </div>
    </el-card>

    <!--列表设置弹窗-->
    <div class="dialog">
      <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
      </el-dialog>
    </div>

    <Edit v-if="showEdit === true" :data="current" :dict="dict" :visible.sync="showEdit"/>

  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable"
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
//引入客户详情
import Edit from './components/edit.vue'

// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {
  getusers, Saveset_status,
} from '@/api/custom'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
    Edit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "系统编号",
          prop: "",
          isShow: true
        },
        // {
        //   label: "ID",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "微信昵称",
        //   prop: "nickname",
        //   isShow: true
        // },
        {
          label: "头像",
          prop: "avatar",
          slot: 'avatar',
          isShow: true
        },
        {
          label: "手机号",
          prop: "phone",
          isShow: true
        },
        {
          label: "车牌号",
          prop: "id",
          isShow: true
        },
        {
          label: "下单区域",
          prop: "id",
          isShow: true
        },
        {
          label: "下单次数",
          prop: "",
          isShow: true
        },
        {
          label: "下单金额",
          prop: "",
          isShow: true
        },
        {
          label: "下单项目",
          prop: "",
          isShow: true
        },
        {
          label: "客户评价",
          prop: "",
          isShow: true
        },
        // {
        //   label: "注册时间",
        //   prop: "create_time",
        //   isShow: true
        // },
        // {
        //   label: "停用时间",
        //   prop: "stop_time",
        //   isShow: true
        // },
        // {
        //   label: "注销时间",
        //   prop: "logout_time",
        //   isShow: true
        // },
        // {
        //   label: "状态",
        //   prop: "status",
        //   slot: "status",
        //   isShow: true
        // },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      current: null,
      showEdit: false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '客户字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getusers(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 启用状态
    Enable(row){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        // 参数
        let params = {
          status: '1'
        }
        Saveset_status(row.id,params).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg);
        })
      }, "确定", 'warning');
    },
    Disable(row){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        // 参数
        let params = {
          status: '0'
        }
        Saveset_status(row.id,params).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg);
        })
      }, "确定", 'warning');
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    openEdit(row){
      this.current = row;
      console.log(this.current)
      this.showEdit = true;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
