<!-- 普通客户列表详情 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'普通客户':'普通客户'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">
      <el-row :gutter="18">
        <el-col :span="24">
          <el-form-item label="微信昵称:" prop="">
            <el-input
                :disabled="true"
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="头像:" prop="">
            <img :src="form.avatar" alt="" style="width: 80px;height: 80px;">
          </el-form-item>
          <el-form-item label="手机号:" prop="">
            <el-input
              :disabled="true"
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="注册时间:" prop="">
            <el-date-picker
              :disabled="true"
                v-model="form.create_time"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="停用时间:" prop="">
            <el-date-picker
              :disabled="true"
                v-model="form.stop_time"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="注销时间:" prop="">
            <el-date-picker
              :disabled="true"
                v-model="form.logout_time"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
                :disabled="true"
                clearable
                v-model="form.status"
                placeholder="请选择状态"
                class="ele-fluid">
              <el-option
                  v-for="(item) in dict.type.common_status"
                  :label="item.label"
                  :value="parseInt(item.value)"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <!--<el-button-->
      <!--    style="margin-left: 20px;"-->
      <!--    type="primary"-->
      <!--    :loading="loading"-->
      <!--    @click="save">确定-->
      <!--</el-button>-->
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {

  },
  mounted() {
    console.log(this.data);
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
</style>
